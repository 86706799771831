.fab-progress,
.fab-progress-bar {
    min-height: 8px;
}

.fab-progress-bar {
    background-color: #E5E5E5;
}

.fab-progress {
    background-color: #549AFF;
}