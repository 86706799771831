.tabs-container {
    font-family: "Barlow", sans-serif;
    padding: 18px;
    border-radius: 8px;

    .tabs-btn-group-container {
        width: 60%;
        margin: 0 auto;
    }

    .highlight {
        background: #f44336 !important;
        color: #ffffff !important;
    }

    /* Media Query for Smaller Screens (Phones) */
    @media (max-width: 1440px) {
        .tabs-btn-group-container {
            width: 80%;
            margin: 0 auto;
        }
    }

    @media (max-width: 1024px) {
        .tabs-btn-group-container {
            width: 100%;
            margin: 0 auto;
        }
    }
}