.fab-accordion-container {
    background-color: #FFFFFF;
    font-family: 'Barlow';
    font-style: normal;
    margin: 2rem 20px;

    .fab-accordion-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #121212;
        &.fab-active-accordion{
            font-weight: 500;
        }
    }

    .fab-accordion-item{
        border-bottom: 1px solid #BEC4D4;
        padding: 10px 0px;
    }

    .fab-accordion-content {
        padding: 6px 0px 8px 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #6E7A8A;
        text-align: justify;
    }
}