.fab-input-container{
    display: flex;
    .fab-input{
        width:100%;
        height: 50px;
        background: #F6FAFE;
        border: 1px solid #CCE0FF;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 8px 10px;
        &:focus{
            border: 1px solid #549AFF;
            background: #ffffff;
        }
        
    }
    .fab-input-placeholder{
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #BEC4D4;
    }
    .fab-input-active{
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #121212;
    }
    textarea {
        resize: none;
        height: 120px !important;
    }
}