.app-split-view {
  display: grid;
  grid-template-columns: 25% 5fr 2fr; /* Left section 25%, middle and right sections 5fr and 2fr */
  min-height: calc(
    100vh - 120px
  ); /* Minimum height based on header/footer height */
  grid-template-rows: auto; /* Allow row height to adjust based on content */
}

.left-section {
  overflow-y: auto;
  height: auto; /* Height adjusts based on content */
}

.middle-section,
.right-section {
  overflow-y: auto;
  height: 100%; /* These sections should fill the height of the parent container */
  max-height: 100%; /* Ensure they don't exceed the height of the left section */
}

/* Media Query for Tablets (iPad and smaller screens) */
@media (max-width: 1024px) {
  .app-split-view {
    grid-template-columns: 1fr 2fr; /* Stack the sections vertically */
    grid-template-rows: auto; /* Allow sections to stack */
  }

  .right-section {
    grid-column: 1 / -1; /* Place the right section in the next row */
  }

  .left-section,
  .middle-section,
  .right-section {
    width: 100%;
    height: auto; /* Allow content to adjust dynamically */
  }
}

/* Media Query for Smaller Screens (Phones) */
@media (max-width: 768px) {
  .app-split-view {
    grid-template-columns: 1fr; /* Stack the sections vertically on smaller screens */
    grid-template-rows: auto auto auto auto;
  }

  .left-section,
  .middle-section,
  .right-section {
    width: 100%;
    height: auto; /* Adjust dynamically based on content */
  }
}

/* Optional: For even smaller screens (e.g., small phones), you can further adjust the layout */
@media (max-width: 480px) {
  .app-split-view {
    grid-template-columns: 1fr; /* Ensure it stacks on very small screens */
    grid-template-rows: auto;
  }

  .left-section,
  .middle-section,
  .right-section {
    width: 100%;
    height: auto;
  }
}
