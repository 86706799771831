.fab-layout-main {
    position: relative;

    .fab-layout-top-bg {
       // background-image: url('../../images/backgrounds/main_top_bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top -240px left 0px;
    }

    .fab-layout-base-text {
        padding: 20px;
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        color: #549AFF;
        text-align: center;

        .fab-layout-base-inner-text {
            font-weight: 700;
        }
    }

    .fab-blocked-spammers-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 40px;

        .fab-blocked-spammers-text {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #FC8181;
        }

        .fab-blocked-spammers-count {
            font-weight: bold;
            font-size: 72px;
            line-height: 86px;
            color: #549AFF;
        }
    }

    .fab-download-app-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

        .fab-download-app-info-text {
            font-weight: 500;
            font-size: 32px;
            line-height: 38px;
            color: #121212;
            margin-bottom: 30px;
            text-align: center;

            .fab-download-app-info-inner-text {
                font-weight: 700;
                color: #549AFF;
                line-height: 60px;
            }
        }

        .fab-download-btn {
            align-self: center;
        }
    }

    .fab-working-info-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;

        .fab-working-info-title {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #6E7A8A;
            margin-bottom: 6px;
        }

        .fab-working-info-description {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: #121212;
            margin-bottom: 20px;
        }
    }

    .fab-trusted-by-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        .fab-trusted-by-text {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #202225;
            margin-bottom: 10px;
        }

        .fab-trusted-by-count {
            font-weight: bold;
            font-size: 72px;
            line-height: 86px;
            color: #549AFF;
        }
    }

    .fab-carousel-section {
        margin-bottom: 60px;
    }
    .fab-teams-section{
        margin-bottom: 80px;
        .fab-teams-row{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .fab-team-card{
            margin: 8px;
            width: 173px;
            height: 208px;
            margin-bottom: 100px;
            img{
                max-width: 100%;
                object-fit: contain;
            }
            .fab-team-member-name{
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #121212;
                margin-top: 10px;
            }
            .fab-team-member-designattion{
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #6E7A8A;
                text-align: center;
                margin-top: 4px;
            }
        }
    }
}