/* Footer General Styles */
footer {
  background-color: #fff;
  padding: 32px 16px;
}

/* Section Headers */
footer h4 {
  font-size: 18px;
  margin-bottom: 16px;
  color: #333;
}

/* Footer Links */
footer a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #0056b3;
}

/* Footer Layout */
.w3-row-padding {
  margin: 0 -16px;
}

.w3-row-padding>.w3-col {
  padding: 0 16px;
}

/* App Download Section */
.fab-footer-apps {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 120px;
    object-fit: contain;
    margin-right: 10px;
  }

  .fab-app-coming-soon {
    position: relative;

    img {
      max-width: 120px;
      opacity: 0.6;
    }

    p {
      position: absolute;
      bottom: -10px;
      background: #ffeb3bAA;
      color: #333;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: bold;
      white-space: nowrap;
    }
  }
}

/* Branding Section */
footer .w3-image {
  max-width: 150px;
  margin: 0 auto;
}

.w3-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  object-fit: cover;
}

/* Social Media Section */
footer .w3-row-padding.w3-center img {
  transition: transform 0.3s ease;
}

footer .w3-row-padding.w3-center img:hover {
  transform: scale(1.1);
}

/* Footer Rights Section */
footer .w3-small {
  font-size: 12px;
  color: #666;
}

footer .w3-small a {
  color: #007bff;
  text-decoration: none;
}

footer .w3-small a:hover {
  text-decoration: underline;
}

.fab-image-custom {
  max-width: 120px !important;
  margin-right: 10px !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  footer .w3-row-padding {
    flex-direction: column;
  }

  .fab-footer-apps {
    margin-bottom: 20px;
    img {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

@media (max-width: 480px) {
  footer h4 {
    font-size: 16px;
  }

  footer a {
    font-size: 12px;
  }

  .fab-footer-apps p {
    font-size: 10px;
  }
}