.fab-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 6px;
    padding: 10px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    
    &.fab-btn-link{
        background: transparent;
        color: #549AFF;
    }

    &.primary-btn{
        background: #549AFF;
        color: #FFFFFF;
    }

    &.secondary-btn{
        background: #F5F9FF;
        color: #549AFF;
    }
}

.fab-btn-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}