.box-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    input {
        font-size: 1.8rem;
        color: #121212;
        width: 45px;
        height: 45px;
        margin: 0.3rem;
        text-align: center;
    }

    .single-input {
        border: 1px solid #CCE0FF;
        border-radius: 0.5rem;
        background: #f8f5fd;
        &:focus {
            border: 1px solid #549AFF;
            background: #fff;
        }
    }

    .box-separator{
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

}