/* Base Styles */
$primary-color: #fff; // Primary blue color for navbar and footer
$secondary-color: #3986f6; // White color for text and icons
$text-color: #333; // Dark text color for better contrast
$light-text-color: #555; // Lighter text color for less emphasis
$font-family: "Barlow", sans-serif;

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 12;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: none;
}

a {
  text-decoration: none;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;

  .navbar-logo-img {
    width: 48px;
    object-fit: contain;
  }

  .navbar-brand {
    display: flex;
  }

  .navbar-title {
    color: $secondary-color;
    font-size: 1.8rem;
    font-weight: 700;
    margin-left: 10px;
    line-height: 48px;
  }
}

.navbar-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .navbar-user-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }

  .navbar-down-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }

  .navbar-user-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: $secondary-color;
  }
}

.navbar-user-details {
  display: flex;
  flex-direction: column;
  color: $secondary-color;
  position: absolute;
  top: 72px;
  right: 16px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  gap: 4px;

  .navbar-user-name {
    color: $secondary-color;
    &:hover {
      background-color: #eff6fe;
    }
  }

  .navbar-user-phone {
    font-size: 0.9rem;
    color: $light-text-color;
    padding: 0px 16px 4px 16px;

    &:hover {
      background-color: #eff6fe;
    }
  }

  .logout-btn {
    font-size: 1rem;
    background: $secondary-color;
    border: 1px solid $secondary-color;
    color: $primary-color;
    padding: 4px 8px;
    border-radius: 5px;
    cursor: pointer;
    transition:
      background 0.3s,
      color 0.3s;

    &:hover {
      background: $primary-color !important;
      color: $secondary-color !important;
    }
  }
}

/* Auth Buttons for Large Screens */
.navbar-auth-buttons {
  display: flex;
  gap: 10px;

  .navbar-login-btn,
  .navbar-register-btn {
    background: none;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition:
      background 0.3s,
      color 0.3s;

    &:hover {
      background: $secondary-color;
      color: $primary-color;
    }
  }
}

/* Mobile Drawer */
.navbar-drawer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: $primary-color;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;

  &.open {
    right: 0;
  }

  .navbar-drawer-close {
    cursor: pointer;
    font-size: 36px;
    color: $secondary-color;
    padding: 10px;
    text-align: right;
  }

  .navbar-drawer-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    .navbar-drawer-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 4px;
      font-size: 1rem;
      color: $secondary-color;
      transition:
        background-color 0.3s,
        color 0.3s;

      &:hover {
        background-color: #f0f6fe;
        color: $primary-color;
      }

      &.active-item {
        background-color: #f0f6fe;
        color: $primary-color;
      }

      img,
      svg {
        width: 24px;
        height: 24px;
      }

      span {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .navbar-container {
    padding: 10px;
  }

  .navbar-menu {
    display: none;
  }

  .navbar-hamburger {
    display: block;
    font-size: 30px;
    cursor: pointer;
    color: $secondary-color;
  }

  .navbar-drawer {
    width: 60%;
  }

  .navbar-auth-buttons {
    flex-direction: column;
    gap: 15px;
  }
}

.navbar-logout-btn,
.navbar-mob-btn {
  background: none;
  width: 100%;
  color: $secondary-color;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;

  &:hover {
    background: $secondary-color;
    color: $primary-color;
  }
}

.navbar-logout-btn {
  position: absolute;
  bottom: 80px;
  border-radius: 0px !important;
}

.drawer-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  .drawer-user-icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }

  .drawer-user-details {
    text-align: center;
    color: $text-color;

    .drawer-user-name {
      font-weight: 600;
      font-size: 1.2rem;
    }

    .drawer-user-phone {
      font-size: 1rem;
      color: $light-text-color;
    }
  }
}

.drawer-logout-btn {
  align-self: stretch;
  margin-top: auto;
  background: none;
  border: 1px solid $secondary-color;
  color: $secondary-color;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;

  &:hover {
    background: $secondary-color;
    color: $primary-color;
  }
}
