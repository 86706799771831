.fab-main-card-container {
  position: relative;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 8px;
  background: #FFFFFF;
  overflow: hidden;
  height: 390px;
  z-index: 1;

  .fab-main-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    z-index: 3;

    .fab-mc-top-text {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #121212;
      margin-bottom: 10px;
    }

    .fab-mc-top-not-scam-msg {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #43D441;
      margin-bottom: 20px;
    }
  }


  .fab-mc-phone-input-container {
    display: flex;
    margin-bottom: 20px;
  }

  .fab-mc-btn-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fab-mc-primary-btn {
      margin-bottom: 12px;
    }

    .fab-mc-report-scam-btn {
      background: #ff0303;
      color: #FFFFFF;
    }

    .fab-mc-link-btn-section {
      display: inline-flex;
      justify-content: center;
      margin-top: 16px;
    }
  }

  .fab-mc-default-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -20px;
    left: 0;
    right: 0;
    background-image: url('../../../images/backgrounds/blue_waves.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom -16px left 0px;
    z-index: 2;
  }

  .fab-ac-scam-bg-container {
    width: 100%;
    height: 168px;
    position: relative;
  }

  .fab-mc-not-scam-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../../images/backgrounds/green_waves.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom -16px left 0px;
    z-index: 2;
  }

  .fab-mc-scam-report {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    margin-top: 10px;
  }

  .fab-upi-input-text {
    margin-bottom: 15px;
  }
}

.fab-main-scam-card-container {
  position: relative;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 8px;
  background: #FA8686;
  overflow: hidden;
  min-height: 360px;
  z-index: 1;

  .fab-main-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    z-index: 3;

    .fab-mc-top-section{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .fab-mc-top-scam-msg {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      margin: 0 auto;
    }
  }

  .fab-mc-phone-input-container {
    display: flex;
    margin-bottom: 20px;
  }

  .fab-mc-btn-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;

    .fab-mc-primary-btn {
      margin-bottom: 12px;
    }

    .fab-mc-report-scam-btn {
      background: #ff0303;
      color: #FFFFFF;
    }
  }

  .fab-mc-default-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../../images/backgrounds/blue_waves.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom -16px left 0px;
    z-index: 2;
  }

  .fab-mc-scam-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../../images/backgrounds/red_waves.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom -16px left 0px;
    z-index: 2;
  }

  .fab-upi-input-text {
    margin-bottom: 15px;
  }

  .fab-scam-bg {
    background: #43D441 !important;
  }

  .fab-mc-scam-report {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    .fab-mc-scam-sub-report{
      font-weight: 500;
    }
  }

  .fab-mc-report-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .fab-mc-scam-active {
    background: #A72017;
    border-radius: 4px;
    padding: 5px 15px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }

  .fab-openai-scam-report-container {
    padding-bottom: 16px;
    .fab-openai-scam-report-text {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: justify;
      color: #ffffff;
    }
  }
}

.fab-mc-scam-click-here {
  text-decoration: underline;
  cursor: pointer;
}

.fab-mc-scam-dot {
  font-size: 35px;
  margin-top: -18px;
}

.fab-mc-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F5F9FF;
  border: 1px solid #CCE0FF;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 14px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
      display: none;
  }

  .fab-mc-btn-grp-active-btn {
    background: #549AFF;
    border-radius: 4px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF !important;

    &:hover, :focus,:active{
      background: #549AFF !important;
    }

  }

  .fab-mc-btn-grp-btn-text {
    padding: 8px 20px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #6E7A8A;
    width: 100%;
    @media only screen and (max-width: 1440px) {
      padding: 8px;
    }
    @media only screen and (max-width: 486px) {
      padding: 8px 0px;
    }
  }

}