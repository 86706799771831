//Auth-card
.fab-auth-card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 4px 6px 20px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    overflow: hidden;
    z-index: 1;

    .fab-auth-card {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 20px;
        z-index: 3;
    }

    .fab-ac-top-text {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #121212;
        margin-bottom: 20px;
    }

    .fab-ac-default-bg-container {
        width: 100%;
        height: 168px;
        position: relative;
        margin-top: -12px;

        .fab-ac-default-bg {
            width: 100%;
            height: 100%;
            background-image: url('../../images/backgrounds/blue_waves.png');
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 2;
        }
    }
}

//Register
.fab-register-container {
    display: flex;
    flex-direction: column;

    .fab-register-info-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        white-space: pre-wrap;
        font-size: 18px;

        a {
            text-decoration: underline;
        }
    }
}

//Login
.fab-login-container {
    display: flex;
    flex-direction: column;

    .fab-login-btn-section {
        display: flex;
        flex-direction: column;

        .fab-forgot-btn {
            margin-bottom: 20px;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color:#549aff;
            line-height: 17px;
            text-decoration: underline;
        }

        a {
            font-size: 18px;
        }
    }

    .fab-login-info-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        white-space: pre-wrap;
        font-size: 18px;
        margin-top: 20px;

        a {
            text-decoration: underline;
        }
    }
}

//Forgot-password
.fab-forgot-pass-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

//Reset pass or Verify-otp
.fab-verify-otp-container {
    display: flex;
    flex-direction: column;

    .fab-verify-top-btn-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.fab-timer-text{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #6E7A8A;
}