.fab-about-main-container {
    .fab-hola-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
    }

    .fab-hola-title {
        color: #549AFF;
        font-weight: 700;
        font-size: 48px;
        font-family: 'Barlow';
        font-style: normal;
    }

    .fab-teams-section {
        margin: 70px 0;

        .fab-teams-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .fab-team-card {
            margin: 8px;
            width: 173px;
            height: 208px;
            margin-bottom: 100px;

            img {
                max-width: 100%;
                object-fit: contain;
            }

            .fab-team-member-name {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #121212;
                margin-top: 10px;
            }

            .fab-team-member-designattion {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #6E7A8A;
                text-align: center;
                margin-top: 4px;
            }
        }
    }

    .fab-company-section {
        margin-bottom: 25px;
        .fab-cgs-logo-section {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 25px;
        }
    }

    .fab-about-discord-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        span {
            margin-right: 12px;
        }
    }


    .fab-about-social-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;

        .fab-about-social-btn {
            margin-right: 35px;

            &:last-child {
                margin-right: 0px;
            }

            img {
                max-width: 100%;
                object-fit: contain;
            }

        }
    }
}