.fab-phone-input-container {
    display: flex;
    width: 100%;
    height:50px;

    .fab-phone-code-container {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 8px;
        padding-right: 10px;
        padding-left: 10px;
        background: #F6FAFE;
        border: 1px solid #CCE0FF;
        box-sizing: border-box;
        border-radius: 6px;

        .fab-country-flag {
            object-fit: contain;
        }
        .fab-phone-code{
            margin-left:8px;
        }
    }

    .fab-phone-input {
        width:95%;
        flex: 1;
        background: #F6FAFE;
        border: 1px solid #CCE0FF;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 8px 10px;
        &:focus{
            border: 1px solid #549AFF;
            background: #ffffff;
        }
    }
    .fab-phone-input-placeholder{
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #BEC4D4;
    }
    .fab-phone-input-active{
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #121212;
    }
}