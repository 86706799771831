.fab-leader-board-container {
    font-family: "Barlow";
    font-style: normal;
    min-height: 600px;

    // Table Styling
    .w3-table-all {
        width: 100%;
        border-collapse: collapse;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        th,
        td {
            padding: 8px 12px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #549aff; // Blue header background
            color: white; // White text for contrast
            font-weight: bold;
        }

        td {
            font-size: 14px;
            font-weight: 500;

            &.lb-name {
                font-weight: 400;
            }
        }
    }

    // Table Row Styling
    .fab-lb-tr {
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #f0f5ff; // Light blue on hover
        }

        &:nth-child(even) {
            background-color: #f9f9f9; // Subtle alternating row background
        }
    }

    // Button Styling
    .fab-lb-btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 4px;
        color: #549aff;
        width: 28px;
        height: 28px;
        background: #ebf3ff;
        border-radius: 60%;
    }

    .fab-lb-btn-text {
        font-size: 14px;
        margin-inline: 12px;
    }

    // Button Group Styling
    .fab-btn-group {
        .fab-btn-grp-btn-text {
            font-size: 12px;
            padding: 4px 12px;
        }
    }

    // Center Text Alignment
    .w3-center {
        text-align: center;
    }

    .custom-margin-top {
        margin: 1px 16px 16px 16px;
    }

    @media (max-width: 600px) {
        .custom-margin-top {
            margin: 16px !important;
        }
    }
}