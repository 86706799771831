.profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    text-align: center;
    user-select: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    // Optional hover effect
    &:hover {
      opacity: 0.9;
    }
  }
  