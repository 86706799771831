.w3-modal {
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;

    .fab-modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); // Default overlay color
    }

    .w3-modal-content {
        position: relative;
        border-radius: 8px;
        padding: 16px;
        overflow: hidden;
		// background-color: transparent;
		min-width: 10px;

        // Ensure close button visibility
        .fab-modal-cross {
            position: absolute;
            top: 24px; // Adjust spacing as needed
            right: 24px; // Adjust spacing as needed
            width: 25px;
            height: 25px;
            background-image: url('../../../images/icons/close_btn.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
			z-index: 51;

            // High contrast for transparent background
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 50%; // Optional: make it circular
        }
    }

    &.transparent-background {
        background-color: transparent !important;
    }
}
